import React, { Component } from "react";
import style from "./Style.module.css";
import { ReactComponent as WhatsAppIcon } from "../../../assets/vectors/whatsapp_white.svg";
import { ReactComponent as FacebookIcon } from "../../../assets/vectors/facebook_white.svg";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import { is_empty } from "../../../utils/validations";
import { appendUrlParams } from "utils/urlUtils";
import constants from "constants/constants";

class Share extends Component {
  render() {
    const { link, title, header, setShowShare, eventData, toast, share_text } =
      this.props;

    let auth = JSON.parse(localStorage.getItem("auth"));

    return (
      <div className={`${style.alert} alert_modal`}>
        <div
          className={`${style.row} ${style.bold_black_large_text} text-center`}
        >
          {header}
        </div>
        {!is_empty(auth) && auth.sub_domain && (
          <div className={style.row}>
            <div
              className={style.copyUrl}
              onClick={() => {
                if (navigator && navigator.clipboard) {
                  navigator.clipboard.writeText(
                    !is_empty(eventData) &&
                      !is_empty(eventData.metadata) &&
                      !is_empty(eventData.metadata.share_url)
                      ? eventData.metadata.share_url
                      : link
                  );
                  toast("Link Copied!");
                }
              }}
            >
              <FileCopyIcon
                height="18px"
                fillcolor="#ffffff"
                style={{ margin: "0 0 0 5px" }}
              />
              <p style={{ margin: "0px 0 0 10px", overflow: "hidden" }}>
                {!is_empty(eventData) &&
                !is_empty(eventData.metadata) &&
                !is_empty(eventData.metadata.share_url)
                  ? eventData.metadata.share_url
                  : link}
              </p>
            </div>
          </div>
        )}

        {!is_empty(auth) && auth.sub_domain && (
          <div className={style.row}>
            <div
              onClick={() => {
                const share = `https://api.whatsapp.com/send?&text=${
                  !is_empty(share_text)
                    ? share_text
                    : !is_empty(eventData)
                    ? ` Join my session, ${title} via ${
                        !is_empty(eventData) &&
                        !is_empty(eventData.metadata) &&
                        !is_empty(eventData.metadata.share_url)
                          ? eventData.metadata.share_url
                          : link
                      }`
                    : `Have a look at my webpage, ${link}`
                }`;
                window.open(share);
                setShowShare(false);
              }}
              className={style.whatsappButton}
            >
              <WhatsAppIcon height="18px" fillcolor="#ffffff" />
              <p style={{ margin: "0px 0 0 10px" }}>WhatsApp</p>
            </div>
          </div>
        )}

        <div className={style.row}>
          <div
            onClick={() => {
              const share = appendUrlParams(constants.facebook_share_url, {
                link: link,
                quote: title,
              });
              window.open(share);
              setShowShare(false);
            }}
            className={style.fbButton}
          >
            <FacebookIcon height="18px" fillcolor="#ffffff" />
            <p style={{ margin: "0px 0 0 10px" }}>Facebook</p>
          </div>
        </div>
      </div>
    );
  }
}

export default Share;
