import React from "react";
import constants from "../../../constants/constants";
import { is_empty } from "../../../utils/validations";
import Tooltip from "../Tooltip";
import { getUserCurrencySymbol } from "../../../utils/AuthUtil";
import { Avatar } from "@my-scoot/component-library-legacy";
import { DEFAULT_EXCHANGE_RATE } from "features/Common/constants/Pricing.constants";

const MoneyField = ({
  record = {},
  source,
  showGstHelper,
  isINR = false,
  creator_sales = true,
  noRightMargin,
}) => {
  if (is_empty(record[source]) && record[source] !== 0) return "N/A";

  const recordExchangeRate = record.exchange_rate ?? DEFAULT_EXCHANGE_RATE;

  const tooltips = [
    record.currency !== constants.inr && !isINR && !creator_sales
      ? `${constants.CURRENCY_SYMBOL_MAP[record.currency]}
          ${record[source]?.toFixed(2)} * ${recordExchangeRate?.toFixed(2)} = 
          ${getUserCurrencySymbol()}${(
          record[source] * recordExchangeRate
        )?.toFixed(2)}`
          .replace(/(\r\n|\n|\r)/gm, "") // Remove all CR(carriage return)[\r] and LF(line feed)[\n] characters
          .replace(/(\s+)/gm, "\u00A0") // Replace all remaining space characters with no-break space character to avoid unintended line breaks
      : null,
    showGstHelper &&
    record?.gst_status &&
    record?.gst_status !== constants.GST_STATUS.INAPPLICABLE
      ? `Includes your GST = ${constants.inr_currency}
        ${(
          (record[source] *
            (creator_sales ? DEFAULT_EXCHANGE_RATE : recordExchangeRate) *
            (record?.creator_gst_percent || constants.GST_PERCENTAGE)) /
          100
        )?.toFixed(2)}`
          .replace(/(\r\n|\n|\r)/gm, "") // Remove all CR(carriage return)[\r] and LF(line feed)[\n] characters
          .replace(/(\s+)/gm, "\u00A0") // Replace all remaining space characters with no-break space character to avoid unintended line breaks
      : null,
  ].filter((t) => t);

  return (
    <div
      style={{ position: "relative" }}
      className={`d-flex align-items-center`}
    >
      {record.currency ? (
        <Avatar
          className="m-2"
          processInitials={false}
          label={record.currency}
        />
      ) : null}
      {`${getUserCurrencySymbol()}${(
        record[source] *
        (isINR || creator_sales ? DEFAULT_EXCHANGE_RATE : recordExchangeRate)
      )?.toFixed(2)}`}
      {tooltips.length > 0 && (
        <Tooltip
          text={tooltips.reduce(
            (accum, curr) => (
              <>
                {accum}
                {accum ? <br /> : null}
                {curr}
              </>
            ),
            null
          )}
          noRightMargin={noRightMargin}
          className={`mb-1 ml-1`}
        />
      )}
    </div>
  );
};

export default MoneyField;
