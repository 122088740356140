import React from "react";

import useStyles from "./ExlySpinnerr.styles";
import BookingLoader from "assets/images/bookingLoader.gif";

const ExlySpinner = ({ loaderWidth, wrapperPadding, wrapperWidth }) => {
  const classes = useStyles({ loaderWidth, wrapperPadding, wrapperWidth });
  return (
    <div className={classes.container}>
      <img className={classes.loader} src={BookingLoader} alt="loading..." />
    </div>
  );
};

export default ExlySpinner;
