import React from "react";
import style from "../../Style.module.css";
import classnames from "classnames";

const ExlyLoader = (props) => {
  const { className, showText = true, ...rest } = props;
  return (
    <div
      style={rest.style}
      className={classnames(
        `${style.loader_conatiner} ${style.mobile_input}`,
        className
      )}
    >
      <div className={`${style.loader_wrapper}`}>
        <div className="loader-wrap">
          <svg
            className="circle-loader progress"
            width="40"
            height="40"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle
              style={{ width: "100%", height: "100%", stroke: "#6c5dd3" }}
              cx="20"
              cy="20"
              r="15"
            ></circle>
          </svg>
        </div>
        {showText && <span>Your data will be ready in a moment</span>}
      </div>
    </div>
  );
};

export default ExlyLoader;
