
import style from './Style.module.css';
import EmailIcon from '@material-ui/icons/MailOutline';
import React from 'react';
import { is_empty } from '../../utils/validations';

const EmailField = ({ record = {}, source }) => {
    if(is_empty(record[source]))    return "N/A";
    return (<span onClick={(event) => {event.preventDefault() ; sendEmail(record[source])}} className={`action_text`}>
        {`${record[source]}`}
        <EmailIcon className={style.whatsapp_icon} />
    </span>);
}

const sendEmail = (email) => {
    let recipient_list = [{ key: "extra", emails: [{ name: email, email: email }], display_name: email }];

    sessionStorage.setItem('recipient_list', JSON.stringify(recipient_list));
    window.location.href = `${window.location.origin}/#/ComposeEmail`;
}

export default EmailField;
export { sendEmail };