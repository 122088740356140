// @ts-nocheck
import React from "react";
// import { FC } from "react";
import { Tooltip } from "@my-scoot/component-library-legacy";
import module_style from "./Style.module.css";
import InfoIcon from "@material-ui/icons/InfoOutlined";
import withComponentLibraryTheme from "hocs/withComponentLibraryTheme";

// interface Props {
//   className?: string;
//   style?: Object;
//   text: String;
//   noRightMargin?: boolean;
//   tooltipProps?: any;
// }

// const ExlyTooltip: FC<Props> = ({
const ExlyTooltip = ({
  text,
  style,
  className,
  noRightMargin,
  infoIconClassName,
  tooltipProps = {},
}) => {
  return (
    <Tooltip title={text} color="primary" arrow {...tooltipProps}>
      <span
        style={style}
        className={`${
          noRightMargin ? "" : module_style.tooltip_margin
        } custom_tooltip ${className}`}
      >
        <InfoIcon className={`info ${infoIconClassName}`} onClick={e => e.stopPropagation()} />
      </span>
    </Tooltip>
  );
};

export default withComponentLibraryTheme(ExlyTooltip);
